import * as React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

const ContactPage = () => (
  <Layout>
    <Helmet title="Contact - Levercode" />
    {/* Content */}
    <div className="container max-w-screen-xl py-32 pb-64 text-2xl">
      <h2 className="text-5xl text-blue-800 ">Contact us</h2>
      <p className="mt-16">We would be happy to hear about you! Please send us an e-mail and we'll get in touch with you shortly.</p>

      <p className="mt-16">
        <strong>Office</strong>
        <br />
        A. H. Tammsaare tee 47, Tallinn, Harjumaa, Estonia
      </p>
      <p className="mt-8">
        <strong>Phone</strong>
        <br />
        +372 6565 600
      </p>
      <p className="mt-8">
        <strong>E-mail</strong>
        <br />
        <a href="mailto:info@levercode.com" className="text-blue-800">
          info@levercode.com
        </a>
      </p>
    </div>
  </Layout>
);

export default ContactPage;
